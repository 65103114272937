/* variable */
:root {
    /* colors */
    --bg: white;
    --theme: #17ACB2;
    --text: #1B1B1B;
    /* bs color */
    --bs-success: #1FD000 !important;
    --bs-warning: #FFB72B !important;
    --bs-danger: #C91515 !important;
    /* fonts */
    --font-small: 8px;
    --font-small-lg: 10px;
    --font-medium: 12px;
    --font-medium-lg: 14px;
    --font-large: 18px;
    --font-large-lg: 20px;
}

/* all elements */
html {
    scroll-behavior: smooth !important;
}

* {
    transition: 0.5s all;
    box-sizing: border-box;
    font-family: YekanBakh, serif;
}

/* body */
body {
    direction: rtl;
    background-attachment: fixed;
    background-color: var(--bg) !important;
}

body::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
    background-color: var(--bg) !important;
}


body::-webkit-scrollbar-thumb,
.modal-body::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background-color: var(--theme) !important;
}

.ltr {
    direction: ltr !important;
}