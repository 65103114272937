/* form floating */
.form-floating label {
    right: auto !important;
    left: auto !important;
}

input,
textarea {
    direction: rtl !important;
    box-shadow: none !important;
    resize: none !important;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    transform: scale(.85) translateY(-1.8rem) translateX(0rem) !important;
}

.form-control,
.rmdp-input,
.form-select,
textarea {
    border-color: #A3A3A3 !important;
}

.form-control:focus,
.rmdp-input:focus {
    border-color: var(--theme) !important;
}

input[type="file"]::-webkit-file-upload-button {
    display: none;
}

input[type="search"] {
    padding-left: 30px;
    background: url('./../../../assets/media/icon/Vector.png') no-repeat 5% 50%;
    background-size: 20px 20px;
    color: var(--text) !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}