.contact-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
}

.contact-card {
    margin-top: -115px;
}

@media screen and (max-width:700px) {
    .contact-img {
        height: 300px;
    }

    .contact-card {
        margin-top: -50px;
    }
}