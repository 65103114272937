.swicher-btn {
    top: 79%;
    left: 35px !important;
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 999999 !important;
    color: var(--bg) !important;
    border-radius: 50% !important;
    background-color: var(--theme) !important;
}

.product-swicher {
    top: 135px !important;
}

.modal {
    z-index: 9999999999 !important;
}