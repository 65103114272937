/* banner style */
.banner-parent {
    margin-top: 40px !important;
    border-radius: 20px !important;
}

.banner-parent .swiper-pagination-bullet::after {
    content: "";
    display: block;
    width: 2px;
    height: 65%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 100%;
    right: 25%;
}

.banner-parent .swiper-pagination-bullet-active::after {
    background-color: var(--theme) !important;
}

.banner-top-box {
    height: 200px !important;
}

.banner-parent .swiper-pagination-bullet:last-child::after {
    display: none;
}

.banner-parent .banner {
    height: 100% !important;
    height: 430px !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    border-radius: 20px !important;
}

@media screen and (max-width:1000px) {
    .banner-parent {
        height: 40dvh;
    }
}

@media screen and (max-width:700px) {
    .banner-parent .container .row {
        margin-top: -20px !important;
    }

    .banner-parent h1,
    .h5 {
        font-size: 17px;
    }

    .banner-parent .card-text {
        font-size: 15px;
    }

    .banner-parent .banner {
        height: 220px !important;
    }

    .banner-parent .swiper-pagination {
        z-index: 9999 !important;
        position: relative !important;
        top: -60px !important;
    }

    .banner-parent {
        height: 31dvh;
    }
}