footer .details {
    background-color: var(--theme);
    border-radius: 40px;
}

.footer-card {
    border-radius: 40px !important;
}


footer .brand-logo {
    width: 150px !important;
    height: 60px !important;
    object-fit: cover !important;
}

.list-group-item {
    border: 0 !important;
    background-color: transparent !important;
}

.bg-icon {
    width: 40px;
    height: 40px;
    padding-top: 7px;
}