.btn-primary {
    border: 0 !important;
    padding: 10px 20px !important;
    color: var(--bg) !important;
    background-color: var(--theme) !important;
}

.btn-outline-primary {
    border-color: var(--theme) !important;
    padding: 10px 20px !important;
    color: var(--theme) !important;
}

.btn-outline-primary:active {
    padding: 10px 20px !important;
    background-color: var(--theme) !important;
}


.border-site {
    border-bottom: 3px solid var(--theme) !important;
}

.btn-outline-primary:hover {
    border-color: var(--theme) !important;
    padding: 10px 20px !important;
    color: var(--bg) !important;
    background-color: var(--theme) !important;
}

.btn-bronze {
    background-color: var(--bronze) !important;
    color: var(--bg) !important;
}

.btn-outline-bronze {
    border: 1px solid var(--bronze) !important;
}

.btn-silver {
    background-color: var(--silver) !important;
    color: var(--bg) !important;
}

.btn-outline-silver {
    border: 1px solid var(--silver) !important;
}

.btn-gold {
    background-color: var(--gold) !important;
    color: var(--bg) !important;
}

.btn-outline-gold {
    border: 1px solid var(--gold) !important;
}

.btn-diamond {
    background-color: var(--diamond) !important;
    color: var(--bg) !important;
}

.btn-outline-diamond {
    border: 1px solid var(--diamond) !important;
}


.admin-page-content .btn-outline-primary {
    padding: 5px 30px !important;
}