/* iranian font */
@font-face {
    font-weight: normal;
    font-family: YekanBakh;
    src: url('../../fonts/yekanbakh/YekanBakhFaNum-Regular.woff');
}

@font-face {
    font-weight: 900;
    font-family: YekanBakhBold;
    src: url('../../fonts/yekanbakh/YekanBakhFaNum-ExtraBold.woff');
}

@font-face {
    font-weight: lighter;
    font-family: YekanBakhLight;
    src: url('../../fonts/yekanbakh/YekanBakhFaNum-Light.woff');
}

/* text and fonts */
.fw-bold {
    font-family: YekanBakhBold, serif !important;
}

.fw-light {
    font-family: YekanBakhLight, serif !important;
}

/* sizes */
.font-small {
    font-size: var(--font-small) !important;
}

.font-small-lg {
    font-size: var(--font-small-lg) !important;
}

.font-medium {
    font-size: var(--font-medium) !important;
}

.font-medium-lg {
    font-size: var(--font-medium-lg) !important;
}

.font-large {
    font-size: var(--font-large) !important;
}