/* swiper */
.swiper-pagination-bullet {
    margin-top: 16px !important;
    background-color: var(--bg) !important;
}

.swiper-pagination-bullet-active {
    width: 20px !important;
    border-radius: 10px !important;
    background-color: var(--theme) !important;
}

.page-slider .swiper-pagination {
    position: relative;
    margin-top: 20px;
}

.banner-parent .swiper-pagination-bullet {
    background-color: var(--text) !important;
}

.banner-parent .swiper-pagination {
    height: 0;
    left: 0 !important;
    right: 0 !important;
    width: 200px !important;
    z-index: 999999 !important;
    top: -10px auto !important;
    margin-left: auto !important;
    position: absolute !important;
    margin-right: auto !important;
    border-bottom: 50px solid var(--bg);
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
}

.swiper-scrollbar {
    margin-right: 10px !important;
    background-color: #757575 !important;
    width: 100% !important;
    position: relative !important;
    margin-top: 40px !important;
}

.new-products .swiper-pagination-bullet {
    background-color: #757575 !important;
}

.new-products .swiper-pagination-bullet-active {
    background-color: var(--theme) !important;
}

.new-products .swiper-pagination {
    position: relative !important;
    margin-top: 20px !important;
}

.category .swiper-scrollbar {
    background-color: #D5D5D5 !important;
}

.swiper-scrollbar-drag {
    background-color: var(--theme) !important;
}

/* navigation */
.prev,
.next {
    z-index: 999999;
    top: 42%;
    position: absolute;
}

.prev {
    left: 5px;
}

.next {
    right: 5px;
}

/* responsive */
@media screen and (max-width: 700px) {
    .banner-parent .swiper-pagination {
        border-bottom: 49px solid var(--bg);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
    }

    .prev,
    .next {
        display: none;
    }
}