.category-card img {
    width: 160px;
    height: 200px;
    object-fit: cover;
}

.new-product-card .card-image {
    background-color: #F3F5F7;
    position: relative;
}

.icon {
    position: absolute;
}

.image-box {
    width: 100px !important;
    height: 100px !important;
    padding-top: 6px !important;
    text-align: center !important;
    border-radius: 50% !important;
    border: 2px solid var(--theme) !important;
}

.image-box:hover {
    outline: 4px solid var(--theme) !important;
}

.image-box img {
    width: 80px !important;
    height: 80px !important;
    object-fit: contain !important;
}

.like-place {
    top: 13px;
    left: 13px;
    width: 30px;
    height: 30px;
    background-color: var(--bg) !important;
    border-radius: 50%;
    padding: 4px 6px;
}

.color-place {
    top: 13px;
    left: 13px;
}

.bg-danger-site {
    background-color: #FF000424 !important;
}

.banner-bg {
    height: 240px !important;
    background: url('./../../../assets/media/banner/banner1.webp') no-repeat center;
    background-size: cover;
    border-radius: 30px;
}

.banner-bg-se {
    background-image: url('./../../../assets/media/banner/banner2.png');
}

.banner-bg-th {
    background-image: url('./../../../assets/media/banner/banner3.png');
}

.banner-bg-fo {
    background-image: url('./../../../assets/media/banner/banner4.png');
}

.banner-bg-fi {
    background-image: url('./../../../assets/media/banner/banner5.png');
}

.banner-bg-last {
    background-image: url('./../../../assets/media/banner/banner5.png');
}

.more-sell .card-image {
    background: linear-gradient(180deg, #17ACB2 0%, rgba(255, 255, 255, 0) 86.45%);
}

.more-sell img {
    width: 180px;
}

.bg-footer-card {
    background-color: var(--theme) !important;
    padding: 5px;
    border-radius: 0px 0px 10px 10px;
}

.brands img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

@media screen and (max-width:1000px) {
    .bg-offer {
        height: 200px !important;
        border-radius: 0 !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .bg-offer-se {
        display: none;
    }

    .banner-bg-se,
    .banner-bg-th,
    .banner-bg-fo,
    .banner-bg-fi {
        height: 180px !important;
        font-size: 14px !important;
    }

    .category-card img {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

    .banner-small {
        height: 150px !important;
        object-fit: cover !important;
    }
}