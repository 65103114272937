.address-detail {
    height: 20px;
}

.offer-detail-suggest {
    background-color: #D3FDFF;
    border-radius: 4px;
    height: 40px;
    padding-top: 10px;
}

.offer-detail-suggest-child {
    color: #17ACB2;
    font-size: 16px;
    font-weight: 700;
    align-items: center;
}

.offer-detail-suggest-child2 {
    color: #17ACB2;
    font-size: 15px;
}

.address-detail-paraghragh {
    font-weight: 400;
    line-height: 22px;
    color: #303030;
}

.detail-product-name {
    font-size: 16px;
    font-weight: 600;
}

.img-detail-far {
    width: 100%;
}

.img-detail-far-img {
    border: 1px solid #D9D9D9;
    border-radius: 15px;
}


.detail-rate-child {
    font-size: 14px;
    font-weight: 700;
    color: #C0C2C9;
}

.detail-color input {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: red;
    outline: none;
    border: none;
}

.detail-color input:focus {
    outline: none;
    border: none;
}

.bnr-detail {
    opacity: 40%;
    height: 303px;
}

.bnr-detail img {
    height: 303px;
}

.detail-text-info-p {
    font-size: 14px;
    font-weight: 700;
    width: 250px;
    padding: 7px 0px;
    /*background-color: red;*/
    border: 2px solid #D9D9D9;
    border-radius: 8px;

}

.line-detail {
    width: 31%;
    height: 3px;
    background-color: #D9D9D9;
    display: flex;
    align-self: center;
}

.detail-text-info-child {
    font-size: 15px;
}

.topic-detail-text-parent {
    box-sizing: border-box;
    background-color: #F0F0F1;
    border-radius: 8px;
    text-align: start;
    padding-top: 0.3em;
}

.topic-detail-text {
    font-size: 10px;
    font-weight: 300;
    line-height: 18px;
}

.topic-detail-text-child {
    font-size: 12px;
    font-weight: 300;
    line-height: 23px;
}

.detail-list-info {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    padding-top: 20px;
    border-radius: 8px;
}

.list-detail-par {
    font-size: 16px;
    font-weight: 600;
    color: #17ACB2;
}

.list-detail-i {
    color: #17ACB2;
    margin-right: 5px;
}

.list-detail-parA {
    color: #808080;
    font-size: 16px;
    font-weight: 600;
}

.list-detail-iA {
    color: #808080;
}


.detail-attribute-p {
    font-size: 16px;
    font-weight: 700;
    color: #0D0D0D;
    width: 10%;
    border-bottom: 3px solid #17ACB2;
    padding-bottom: 5px;
}

.detail-attribute-des {
    font-size: 14px;
    font-weight: 400;
}

.joz-att {
    cursor: pointer;
}

.joz-att a {
    text-decoration: none;
}

.detail-att-table-p {
    color: #5B5B5B;
    font-size: 13px;
}

.detail-att-table-pM {
    color: #000;
    font-size: 15px;
}

.price-item-name {
    font-size: 14px;
    color: #4A4A4A;
}

.price-item-name2 {
    color: #17ACB2;
    font-size: 12px;

}

.acband-price-icon {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-top: -30px !important;
}

.btn-price {
    background-color: #17ACB2;
    height: 30px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;

}

.price-description {
    font-size: 10px;
    margin-top: 10px;
    color: #474747;
}

.delivery-price {
    color: #474747;
    font-size: 8px;
}

.info-t {
    color: #616161;
}

.text-2 {
    padding: 10px;
    color: black;
    background-color: #F2F2F2;
}

.product-icons {
    top: 70px !important;
}

@media screen and (max-width:1000px) {
    .detail-attribute-p {
        width: 100%;
    }

    .product-banner-sm {
        height: 130px !important;
    }

    .ablity-icons img {
        width: 70px !important;
        height: 70px !important;
    }
}