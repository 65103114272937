/* colors */
.text-site,
.card-title {
    color: var(--theme) !important;
}

.card-text {
    text-align: justify;
}

/* line height */
.line-30 {
    line-height: 30px !important;
}

.line-25 {
    line-height: 25px;
}

/* text style */
.text-justify {
    text-align: justify;
}

.text-split {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.text-table {
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}