.about-img {
    width: 100%;
    height: 430px;
    object-fit: cover;
    border-radius: 10px !important;
}

@media screen and (max-width:700px) {
    .about-img {
        height: 300px;
    }
}