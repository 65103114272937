.bg-site {
    background-color: #1B1B1B !important;
}

.navbar-toggler {
    outline: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.logo-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
}

header {
    left: 0 !important;
    top: 0 !important;
    position: fixed !important;
    z-index: 999999999 !important;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.offer {
    padding: 10px;
    background-color: var(--theme);
    z-index: 999999999 !important;
}

/* mobile header */
.header-mobile {
    width: 80% !important;
    bottom: 10px;
    left: auto;
    right: auto;
    z-index: 999 !important;
    background-color: var(--text) !important;
    font-size: 12px;
    border-radius: 40px;
}

.btn-phone .btn {
    border: 0 !important;
    color: var(--bg) !important;
}

.navbar .active {
    color: var(--theme) !important;
}

.active {
    font-weight: 600 !important;
    color: var(--theme) !important;
}

.activator {
    width: 60px;
    height: 60px;
}

.active .activator {
    width: 60px;
    height: 60px;
    transform: translateY(-15px);
    background-color: var(--bg) !important;
    border-radius: 50%;
}


.active .activator .btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: var(--bg) !important;
    background-color: var(--theme) !important;
}

nav input::placeholder {
    color: var(--text) !important;
    font-size: 10px !important;
}